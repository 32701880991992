<div class="app" *ngIf="loaded$ | async">
  <div class="app__inner" [class.anim--in]="loading$ | async">
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [menuActive]="(menuOpen$ | async) ?? false"
      [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
      [inverse]="(menuInverse$ | async) ?? false"
      *ngIf="loaded$ | async"
    ></app-topbar>

    <div class="app__router">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <app-footer
      class="mt-auto mb-0"
      [domain]="domainName"
      [partners]="('footer-partner-menu' | fromMenu)?.error ? [] : ('footer-partner-menu' | fromMenu)"
      [menu]="('footer-nav' | fromMenu)?.error ? [] : ('footer-nav' | fromMenu)"
      *ngIf="(loaded$ | async) && !hideFooter"
    ></app-footer>
  </div>

  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonAcceptAll]="'cookie-accepteer-alle-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
