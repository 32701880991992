<footer class="footer bg-primary-800 text-light section-inset-t pb-4">
  <div class="container">
    <div class="row">
      <nav class="col-md-3 col-xl-2 mb-5 mb-md-0">
        <ul class="list-unstyled">
          @for (page of menu; track page) {
            <li class="topbar__nav__list__item mb-3">
              <a class="footer-link text-link text-light" [routerLink]="page.url">
                {{ page.title }}
              </a>
            </li>
          }
          <li class="footer__nav-list__item">
            <button (click)="openCookie()" class="footer-link">{{ 'cookie-settings' | fromDictionary }}</button>
          </li>
        </ul>
      </nav>
      <div class="col-md-3">
        <div [innerHTML]="'footer-contact' | fromDictionary"></div>
      </div>
      <nav class="col-md-5 mt-5 mt-md-0 me-0 ms-auto text-md-end">
        <strong class="d-block mb-3">{{ 'footer-partners-titel' | fromDictionary }}</strong>

        <ul class="list-unstyled">
          @for (page of partners; track page) {
            <li class="topbar__nav__list__item mb-3">
              <a [href]="page.url" target="_blank" *ngIf="page.url" class="footer-link text-link text-light">{{ page.title }}</a>
            </li>
          }
        </ul>
      </nav>
    </div>
  </div>
  <div class="footer__end section-inset-t text-center">
    <span class="">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</span>
  </div>

  <app-json-ld [json]="schema"></app-json-ld>
</footer>
