<div class="topbar py-md-4" [class.set--inverse]="inverse">
  <div class="container d-flex align-items-center justify-content-between flex-row-reverse flex-sm-row gap-3">
    <h1 class="topbar__logo mb-0">
      <a [routerLink]="'/'">
        <span class="sr-only">Climate Campus</span>
        <img [src]="'logo-topbar' | fromDictionary" alt="Climate Campus logo" />
      </a>
    </h1>
    <nav class="topbar__nav">
      <ul class="topbar__nav__list d-flex">
        @for (page of menu; track page) {
          <li class="topbar__nav__list__item">
            <a
              (click)="closeOverlay.emit()"
              class="button button--primary"
              [routerLink]="page.url"
              [routerLinkActive]="'is--current'"
              [routerLinkActiveOptions]="{ exact: page.url === '/' }"
            >
              {{ page.title }}
            </a>
          </li>
        }
      </ul>
    </nav>
    <button class="button button-link button--primary d-inline-flex d-md-none topbar__toggle" (click)="toggleOverlay.emit()">
      <span>Menu</span>
      <fstr-icon class="bg-primary text-light multiple" anchor="icon-menu-wave"></fstr-icon>
      <fstr-icon class="bg-primary text-light single" anchor="icon-menu-single-wave"></fstr-icon>
    </button>
  </div>
</div>
