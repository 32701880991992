import { createReducer, on } from '@ngrx/store';
import * as NavActions from '../actions/nav.action';

export interface NavState {
  menuOpen: boolean;
  inverse: boolean;
}

export const initialState: NavState = {
  menuOpen: false,
  inverse: false,
};

export const NavReducer = createReducer(
  initialState,
  on(NavActions.OpenMenu, state => {
    return {
      ...state,
      menuOpen: true,
    };
  }),
  on(NavActions.CloseMenu, state => {
    return {
      ...state,
      menuOpen: false,
    };
  }),
  on(NavActions.ToggleMenu, state => {
    return {
      ...state,
      menuOpen: !state.menuOpen,
    };
  }),
  on(NavActions.SetInverse, (state, { inverse }) => {
    return {
      ...state,
      inverse,
    };
  })
);

export const getMenuOpen = (state: NavState) => state.menuOpen;
export const getMenuInverse = (state: NavState) => state.inverse;
